





































import { Component, Watch } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import EstimationProductGroup from "@/types/Estimation/EstimationProductGroup"
import EstimationApi from "@/api/Estimation/EstimationApi"
import _ from "lodash"
import { deserialize } from "typescript-json-serializer"
import BreakpointMixin from "../../types/BreakpointMixin"

@Component({
  name: "CalculateList"
})
export default class CalculateList extends mixins(BreakpointMixin) {
  private productGroups: EstimationProductGroup[] = []

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private fetchData() {
    EstimationApi.listProductGroups().then(({ data }) => {
      this.productGroups = _.map(data, (d) => deserialize<EstimationProductGroup>(d, EstimationProductGroup))
    })
  }

  private mounted() {
    this.fetchData()
  }

  // private viewGroup(group: EstimationProductGroup) {
  //   const lang = this.$i18n.locale
  //   this.$router.push({
  //     name: "CalculateCategoryList",
  //     query: { group: `${group.productGroupId}`, lang }
  //   })
  // }

  private generateRoute(group: EstimationProductGroup) {
    return {
      name: "CalculateCategoryList",
      query: {
        group: `${group.productGroupId}`,
        lang: this.$i18n.locale
      }
    }
  }

  private get imageHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 392
    }
    if (bp.mdAndUp) {
      return 275
    }
    return 275
  }
}
